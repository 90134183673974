var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('div',{staticClass:"5"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.invoiceList,"items-per-page":_vm.defaultPerPage,"footer-props":{'items-per-page-options': [3, 5, 10]},"server-items-length":_vm.invoicesLength,"data-test":"dataTable-field","options":_vm.pagination},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.paid)?_c('v-chip',{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(item.status)+" ")]):(!item.paid && item.status=='open' && item.attempted)?_c('v-chip',{attrs:{"color":"#E53935"}},[_vm._v(" "+_vm._s('payment failed')+" ")]):_c('v-chip',[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.amountDue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.amountDue,item.currency))+" ")]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("unixTimeFormat")(item.dueDate))+" ")]}},{key:"item.pdf",fn:function( ref ){
var item = ref.item;
return [(item.pdf!='---')?_c('a',{attrs:{"href":item.pdf,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"#E53935"}},[_vm._v(" mdi-file-pdf-box ")])],1):_c('div',[_vm._v(" "+_vm._s(item.pdf)+" ")])]}},{key:"item.url",fn:function( ref ){
var item = ref.item;
return [(item.url!='---')?_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-credit-card ")])],1):_c('div',[_vm._v(" "+_vm._s(item.url)+" ")])]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }