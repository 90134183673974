var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('div',{staticClass:"mt-5"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.paymentList,"hide-default-footer":"","data-test":"dataTable-field"},scopedSlots:_vm._u([{key:"item.brand",fn:function(ref){
var item = ref.item;
return [_c('BillingIcon',{attrs:{"icon-name":item.brand}})]}},{key:"item.last4",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.last4)+" ")]}},{key:"item.expdate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.expMonth)+" / "+_vm._s(item.expYear)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!item.default)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.updatePaymentMethod(item.id)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_c('v-list-item-title',[_vm._v(" Make default ")])],1),_c('v-list-item',{on:{"click":function($event){$event.stopPropagation();return _vm.deletePaymentMethod(item.id)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-delete ")]),_c('v-list-item-title',[_vm._v(" Remove ")])],1)],1)],1):_c('div',[_vm._v(" Default ")])]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }